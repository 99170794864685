<template lang="pug">
page-section.sw-section.sw-section--active-current.page-pb-lg.relative(
  align='center',
  class='page-pt-md md:page-pt-md-alt'
)
  template(slot='content')
    .d-none.d-md-block.fw-bold.fs-mega Active Giveaways
    ea-active-giveaway(
      v-for='g in currentActiveGiveaways',
      :key='g.idToken',
      :giveaway='g'
    )
  .after-shadow(slot='append')
</template>
<script>
import EaActiveGiveaway from './EaActiveGiveaway.vue'

export default {
  props: {
    currentActiveGiveaways: {
      type: Array,
      required: true
    }
  },
  components: {
    EaActiveGiveaway
  }
}
</script>
