<template lang="pug">
.ea-sw-not-active-giveaway.no-underline.d-flex.flex-column(
  v-bind='wrapAttrs',
  :class='wrapClasses'
)
  .ea-sw-not-active-giveaway__main.d-flex.flex-column.flex-grow-1
    .ea-sw-not-active-giveaway__img
      img.max-w-100(:src='imgSrc', :alt='title', :title='title')
    .bg-color-sw-tile.page-py-sm.page-px-sm.d-flex.flex-column.justify-content-center.flex-grow-1
      .ea-sw-not-active-giveaway__title.fw-bold.fs-md.color-white(v-html='swTitle')
      .ea-sw-not-active-giveaway__winner.page-pt-sm.page-pb-xs(v-if='showWinners')
        user-display.color-sw-holocron-gold(
          v-for='winner in winners',
          v-if='canShowWinner(winner)',
          :key='winner.user.id',
          :user='winner.user',
          :ellipsis='true',
          :color-inherit='true',
          :avatar-src='winner.user.avatar',
          avatar-display-size='lg-alt',
          extra-name-class='fw-bold fs-md',
          extra-name-append-class='fs-sm page-pt-xs'
        )
          template(slot='name-append') WINNER
    .ea-sw-not-active-giveaway__state.page-py-xs.fw-bold(:class='stateClasses') {{ stateDisplay }}
</template>
<script>
export default {
  props: {
    giveaway: {
      type: Object,
      required: true
    },
    winners: {
      type: Array,
      required: true
    }
  },
  computed: {
    wrapAttrs() {
      if (this.giveaway.isEnded) {
        return {
          is: 'a',
          href: this.url,
          target: '_blank'
        }
      }

      return null
    },
    wrapClasses() {
      return `ea-sw-not-active-giveaway--${this.giveaway.isEnded ? 'ended' : 'upcoming'}`
    },
    title() {
      return this.giveaway.record.title
    },
    swTitle() {
      // Makes sure "Star Wars" is italicized
      const title = this.title
      return this.$helpers.addClassesToWordInCopy(title, 'star wars', 'italics')
    },
    imgSrc() {
      return this.giveaway.record.image
    },
    url() {
      return `${this.$helpers.giveawayUrl(this.giveaway.record.idToken)}/ended`
    },
    showWinners() {
      if (!this.giveaway.isEnded || !this.winners.length) {
        return false
      }

      return this.winners.some((w) => this.canShowWinner(w))
    },
    stateDisplay() {
      return this.giveaway.isEnded ? 'ENDED' : 'UPCOMING'
    },
    stateClasses() {
      if (this.giveaway.isEnded) {
        return 'bg-color-sw-chaos-red color-white'
      }

      return 'bg-color-sw-tile-upcoming color-white-60'
    }
  },
  methods: {
    canShowWinner(winner) {
      return ['pending', 'completed'].includes(winner?.aasmState)
    }
  }
}
</script>
