<template lang="pug">
page-section.sw-section.sw-section--not-active.page-pt-lg(align='center')
  template(slot='content')
    template(v-if='notActiveGiveaways.length')
      .fw-bold.fs-xl.page-mb-md All Giveaways
      .d-flex.flex-wrap.align-items-stretch.justify-content-start
        ea-not-active-giveaway(
          v-for='g in notActiveGiveaways',
          :key='g.idToken',
          :giveaway='g',
          :winners='getWinners(g.id)'
        )
    .fs-xs.text-center(:class='{ "page-mt-md": notActiveGiveaways.length }')
      span.fw-bold NO PURCHASE NECESSARY.&nbsp;
      span Subject to Official Sweepstakes Rules found within the Terms &amp; Conditions of each Sweepstakes. Void where prohibited.
</template>
<script>
import EaNotActiveGiveaway from './EaNotActiveGiveaway.vue'

export default {
  props: {
    notActiveGiveaways: {
      type: Array,
      required: true
    },
    winnersByGiveaway: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    getWinners(giveawayId) {
      if (!Object.keys(this.winnersByGiveaway).length || !this.winnersByGiveaway[giveawayId]?.length) {
        return []
      }

      return this.winnersByGiveaway[giveawayId]
    }
  },
  components: {
    EaNotActiveGiveaway
  }
}
</script>
