<template lang="pug">
.ea-sw-active-giveaway.page-mt-md
  .ea-sw-active-giveaway__img.page-mb-md
    a(:href='url', target='_blank')
      img.max-w-100.max-w-480p--after-md.max-w-650p--after-lg(:src='imgSrc', :alt='title', :title='title')
  .ea-sw-active-giveaway__title.page-mb-sm.fw-bold.max-w-75--until-lg.max-w-500p--after-lg.mx-auto(
    class='fs-lg md:fs-mega',
    v-html='swTitle'
  )
  b-btn(variant='sw-gold', :href='url', target='_blank') Enter Giveaway
</template>
<script>
export default {
  props: {
    giveaway: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      return this.giveaway.record.title
    },
    swTitle() {
      // Makes sure "Star Wars" is italicized
      const title = this.title
      return this.$helpers.addClassesToWordInCopy(title, 'star wars', 'italics')
    },
    imgSrc() {
      return this.giveaway.record.image
    },
    url() {
      return this.$helpers.giveawayUrl(this.giveaway.record.idToken)
    }
  }
}
</script>
