<template lang="pug">
page-section.sw-section.sw-section--video.bg-color-sw-video-section(
  align='center',
  class='page-py-md md:page-pt-md md:page-pb-lg lg:page-px-lg lg:page-py-lg'
)
  template(slot='content')
    .d-flex.flex-column-reverse.flex-lg-row.align-items-center.justify-content-between
      .page-pt-sm.w-100.max-w-250p--until-sm.max-w-100--after-md.max-w-350p--after-lg.max-w-450p--after-xl.fw-bold.text-lg-left(
        class='fs-lg md:fs-xl lg:fs-mega xl:fs-huge'
      )
        | Watch the Official Reveal Trailer
      youtube-video.w-100.max-w-480p--after-sm.max-w-50--after-lg.max-w-600p--after-xl(:youtubeLink='youtubeLink')
</template>
<script>
import YoutubeVideo from '../../../components/YoutubeVideo.vue'

export default {
  data() {
    return {
      youtubeLink: 'https://www.youtube.com/embed/VRaobDJjiec'
    }
  },
  components: {
    YoutubeVideo
  }
}
</script>
