<template lang="pug">
page.sw-jedi-survivor(
  body-classes='sw-jedi-survivor',
  :show-header='false',
  :ga-event-category='gaEventCategory',
  :ga-event-label='gaEventLabel'
)
  //- Content
  template(slot='content')
    //- Banner
    page-section.sw-section.sw-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
        .banner-img-content(v-if='!isEndedView')
          b-btn(variant='sw-white-outline', :href='learnMoreLink', target='_blank') Learn More About The Game

    #main
      //- Loading
      template(v-if='isLoading')
        page-section.sw-section.sw-section--loading.page-pt-lg(align='center')
          template(slot='content')
            loading-icon.page-mt-lg-alt.page-mb-xxl(size='xl', color='sw-holocron-gold')
        ea-video-section.page-mt-lg

      //- Error
      template(v-else-if='isError')
        page-section.sw-section.sw-section--error.page-pt-lg(align='center')
          template(slot='content')
            .fs-lg.page-mb-sm Sorry, there was an error.
            b-btn(variant='sw-gold', @click='refreshPage') Refresh Page
        ea-video-section.page-mt-lg

      //- Ended view
      template(v-else-if='isEndedView')
        page-section.sw-section.sw-section--ended.page-pt-md-alt(align='center')
          template(slot='content')
            .h1 THANK YOU FOR PARTICIPATING!
            .page-pt-md Learn more about <span class='italic'>Star Wars</span> Jedi: Survivor&trade;
            b-btn.page-mt-sm(variant='sw-gold', :href='learnMoreLink', target='_blank') Click Here
        //- Video
        ea-video-section.page-mt-lg
        //- Ended/Upcoming List
        ea-not-active-section(:notActiveGiveaways='notActiveGiveaways', :winnersByGiveaway='winnersByGiveaway')

      //- Upcoming view
      template(v-else-if='!isUnderway')
        page-section.sw-section.sw-section--upcoming.page-pt-lg(align='center')
          ea-starts-in(slot='content', :giveaway='giveawayList[0]')
        ea-video-section.page-mt-lg

      //- Active view
      template(v-else)
        //- In between giveaways
        page-section.sw-section.sw-section--upcoming.page-py-lg(v-if='isBetweenActiveGiveaways', align='center')
          ea-starts-in(slot='content', v-if='nextUpcomingGiveaway', :giveaway='nextUpcomingGiveaway')
        //- Active List
        ea-active-section(v-else, :currentActiveGiveaways='currentActiveGiveaways')
        //- Video
        ea-video-section
        //- Ended/Upcoming List
        ea-not-active-section(:notActiveGiveaways='notActiveGiveaways', :winnersByGiveaway='winnersByGiveaway')

  //- Footer
  template(slot='footer')
    base-footer(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
      .mt-3.mt-sm-0(slot='custom-image')
        img.mr-2(:src='esrbRatingSrc', height='60', alt='ESRB Rating')
</template>
<script>
import BaseFooter from '../../components/BaseFooter.vue'
import EaActiveSection from './components/EaActiveSection.vue'
import EaNotActiveSection from './components/EaNotActiveSection.vue'
import EaVideoSection from './components/EaVideoSection.vue'
import EaStartsIn from './components/EaStartsIn.vue'
import multipleGiveawaysInSyncMixin from '../../mixins/base/multiple-giveaways-in-sync'
import fetchWinnersMixin from '../../mixins/fetch-winners'

export default {
  name: 'StarWars',
  mixins: [multipleGiveawaysInSyncMixin, fetchWinnersMixin],
  data() {
    return {
      pageMetaTitle: 'STAR WARS - JEDI Survivor',
      esrbRatingSrc: require('../../assets/themes/ea-star-wars/images/esrb-rating--pending.png'),
      learnMoreLink: 'https://www.ea.com/games/starwars/jedi/jedi-survivor',
      currentActiveGiveaways: [],
      notActiveGiveaways: [],
      endedGiveaways: [],
      winnersByGiveaway: {},
      nextUpcomingGiveaway: null,
      giveawayInterval: null,
      winnersInterval: null,
      winnersCheckInit: false,
      idTokens: ['QCd1c56', 'inStjkj', 'JmNFSz1', 'oVlxYAD', '6TGCW_s']
    }
  },
  computed: {
    isBetweenActiveGiveaways() {
      if (!this.isUnderway || this.isEndedView) {
        return false
      }

      return !this.currentActiveGiveaways.length
    }
  },
  watch: {
    endedGiveaways() {
      // Check for winners when ended list changes initially
      if (this.endedGiveaways.length && !this.winnersCheckInit) {
        this.winnersCheckInit = true
        this.giveawayWinnersCheck()
      }
    }
  },
  async created() {
    // Giveaway States
    this.giveawayStateCheck()
    this.giveawayInterval = setInterval(this.giveawayStateCheck, 1000)

    // Giveaway Winners
    this.winnersInterval = setInterval(this.giveawayWinnersCheck, 1000 * 30)
  },
  beforeDestroy() {
    clearInterval(this.giveawayInterval)
    clearInterval(this.winnersInterval)
  },
  methods: {
    giveawayStateCheck() {
      this.currentActiveGiveaways = this.giveawayList.filter((g) => g.isActive)
      this.endedGiveaways = this.giveawayList.filter((g) => g.isEnded)
      this.notActiveGiveaways = this.giveawayList.filter((g) => !g.isActive) // ended/upcoming

      // Next upcoming giveaway
      const nextGiveaways = this.giveawayList.filter((g) => g.isUpcoming)
      this.nextUpcomingGiveaway = nextGiveaways.length ? nextGiveaways[0] : null

      // If all done, clear the interval
      if (this.isEndedView && this.notActiveGiveaways.length) {
        clearInterval(this.giveawayInterval)
      }
    },
    async giveawayWinnersCheck() {
      if (!this.endedGiveaways.length) {
        return
      }

      const giveawayIds = this.endedGiveaways.map((g) => g.id)
      await this.$helpers.asyncForEach(giveawayIds, this.getWinnersByGiveaway)
    },
    async getWinnersByGiveaway(giveawayId) {
      if (!giveawayId) {
        return
      }

      const existingWinners = this.winnersByGiveaway[giveawayId] ?? []
      if (existingWinners.some((w) => this.canShowWinner(w))) {
        return
      }

      const { winners } = await this.fetchGiveawayWinners(giveawayId)
      this.$set(this.winnersByGiveaway, giveawayId, winners)
    },
    canShowWinner(winner) {
      return ['pending', 'completed'].includes(winner?.aasmState)
    }
  },
  components: {
    EaStartsIn,
    EaVideoSection,
    EaActiveSection,
    EaNotActiveSection,
    BaseFooter
  }
}
</script>
<style lang="scss" src="../../assets/themes/ea-star-wars/scss/main.scss" />
