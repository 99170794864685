<template lang="pug">
stacked-giveaway-starts-in.ff-sw-display.fw-bold(
  :giveaway='giveaway',
  label='Check Back Soon!',
  label-classes='fs-xl md:fs-mega page-mb-sm page-pl-xs md:page-pl-sm',
  count-wrap-classes='uppercase text-center',
  timeslot-classes='w-80p--until-md w-100p--after-md',
  time-count-classes='fs-lg md:fs-mega',
  time-label-classes='fs-xs md:fs-md'
)
</template>
<script>
import StackedGiveawayStartsIn from '../../../components/StackedGiveawayStartsIn.vue'

export default {
  props: {
    giveaway: {
      type: Object,
      required: true
    }
  },
  components: {
    StackedGiveawayStartsIn
  }
}
</script>
