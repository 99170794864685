<template lang="pug">
.stacked-countdown.d-flex.flex-column.align-items-center.justify-content-center(aria-label='Countdown')
  .stacked-countdown__label.uppercase(v-if='label', :class='labelClasses') {{ label }}
  .stacked-countdown__counter.d-flex.align-items-center(:class='countWrapClasses')
    .stacked-countdown__counter-timeslot(:class='timeslotClasses')
      .stacked-countdown__counter-timeslot-count(:class='timeCountClasses') {{ isFinished ? 0 : countdown.days }}
      .stacked-countdown__counter-timeslot-label(:class='timeLabelClasses') Days
    .stacked-countdown__counter-timeslot(:class='timeslotClasses')
      .stacked-countdown__counter-timeslot-count(:class='timeCountClasses') {{ isFinished ? 0 : countdown.hours }}
      .stacked-countdown__counter-timeslot-label(:class='timeLabelClasses') Hours
    .stacked-countdown__counter-timeslot(:class='timeslotClasses')
      .stacked-countdown__counter-timeslot-count(:class='timeCountClasses') {{ isFinished ? 0 : countdown.minutes }}
      .stacked-countdown__counter-timeslot-label(:class='timeLabelClasses') Minutes
    .stacked-countdown__counter-timeslot(:class='timeslotClasses')
      .stacked-countdown__counter-timeslot-count(:class='timeCountClasses') {{ isFinished ? 0 : countdown.seconds }}
      .stacked-countdown__counter-timeslot-label(:class='timeLabelClasses') Seconds
</template>
<script>
export default {
  data() {
    return {
      isFinished: false
    }
  },
  props: {
    countdown: {
      type: Object,
      required: true
      /*
        format: {
          days: 1,
          hours: 1,
          minutes: 1,
          seconds: 1
        }
      */
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelClasses: {
      type: String,
      required: false,
      default: ''
    },
    countWrapClasses: {
      type: String,
      required: false,
      default: ''
    },
    timeslotClasses: {
      type: String,
      required: false,
      default: ''
    },
    timeCountClasses: {
      type: String,
      required: false,
      default: ''
    },
    timeLabelClasses: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    countdown: {
      handler(countdown) {
        const { days, hours, minutes, seconds } = countdown
        if (days === 0 && hours === 0 && (minutes === 0 || minutes === '00') && (seconds === 0 || seconds === '00')) {
          this.isFinished = true
          this.$emit('finished')
        }
      },
      deep: true
    }
  }
}
</script>
