<template lang="pug">
.youtube-video
  .w-100.max-w-800p.mx-auto.text-center(ref='videoWrap')
    iframe(
      :src='youtubeLink',
      frameBorder='0',
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      :allowFullScreen='true',
      :style='{ width: "100%", height: iframeHeight }'
    )
    slot(name='append')
</template>
<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      iframeHeight: '100px',
      onResize: null
    }
  },
  props: {
    youtubeLink: {
      // embed video link. E.g., https://www.youtube.com/embed/URIy5xLbipM
      type: String,
      required: true
    },
    videoRatio: {
      type: Number,
      required: false,
      default: 1.78 // width to height of 16:9
    }
  },
  created() {
    this.onResize = debounce(this.setIframeHeight, 150)
    this.onResize()

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    setIframeHeight() {
      const wrapWidth = this.$refs.videoWrap.clientWidth
      this.iframeHeight = `${(wrapWidth / this.videoRatio).toFixed(1)}px`
    }
  }
}
</script>
