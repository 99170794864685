import apollo from '../utils/apollo'
import endedContestWinnersQuery from '../queries/endedContestWinners.gql'

export default {
  methods: {
    async fetchGiveawayWinners(contestId, limit = 10, page = 1, cacheSeconds = 10) {
      const winnerDetails = await this.fetchWinners({ contestId, limit, page }, cacheSeconds)
      return winnerDetails
    },
    async fetchWinners(winnerParams, cacheSeconds = 10) {
      let winnerDetails = []

      try {
        const {
          data: { endedContestWinners }
        } = await apollo.client.query({
          query: endedContestWinnersQuery,
          variables: winnerParams,
          context: {
            headers: {
              'x-cache-hint': `public, max-age=${cacheSeconds}`
            }
          }
        })

        winnerDetails = endedContestWinners
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      return winnerDetails
    }
  }
}
